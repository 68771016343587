
import React from "react";

import "./Modal.scss";

class Modal extends React.Component {
  static defaultProps = {
    className: "",
    opacity: 0.7,
  };

  render() {
    const { opacity, onModalClick } = this.props;
    return (
      <div className={`Modal ${this.props.className}`}>
        <div
          className="real-modal"
          style={{ backgroundColor: `rgba(0,0,0,${opacity})` }}
          onClick={() => onModalClick && onModalClick()}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Modal;

import Axios from 'axios';

import md5 from 'md5';
import * as config from '../config';

const { BASE_API_ROOT } = config;

const axios = Axios.create();

axios.defaults.baseURL = BASE_API_ROOT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const formatRequest = (request) => {

  let headersValues = {};

  // 请求时间戳
  headersValues.timestamp = Date.parse(request.headers.date || new Date().toISOString());

  // 随机值，字符串
  headersValues.nonce = Math.random().toString(36).substr(2, 10);

  // 32位签名值，算法参见下方签名算法
  headersValues.sign = md5(`${request.method.toLocaleUpperCase()}/${request.url}${headersValues.timestamp}${headersValues.nonce}`);
  headersValues['check-sign'] = 'y';

  for(let key in headersValues) {
    // Access the value of each key
    request.headers[key] = headersValues[key];
  }
  return request;
};

const formatResponse = (response) => {
  const data = response.data;
  if(data.errCode === 0) {
    return data.data;
  } else {
    return Promise.reject(data);
  }
};

const ERROR_CODE = {
  SERVER_ERROR: 500,
  NETWORK_ERROR: 503,
  AUTHORIZE_ERROR: 401
};

const formatAjaxError = (error) => {
  const response = error.response || {};
  if(response && response.status === ERROR_CODE.SERVER_ERROR) {
    return Promise.reject({
      message: '服务器内部错误，请稍后重试！',
      status: response.status
    });
  }
  if(response && response.status === ERROR_CODE.NETWORK_ERROR) {
    return Promise.reject({
      message: '网络问题，请稍后重试！'
    });
  }
  if(response && response.status === ERROR_CODE.AUTHORIZE_ERROR) {
    // window.location.href = window.location.origin + '/login';
  }
  return Promise.reject(response.data || {
    message: '服务器开小差了呢！'
  });
};

// add a request interceptor
axios.interceptors.request.use(formatRequest, formatAjaxError);

//Add a response interceptor
axios.interceptors.response.use(formatResponse, formatAjaxError);

export default function axiosServices(root) {
  return (method, route, data) => {
    const params = method === 'get' ? { params: data } : data;
    return axios[method](
      `${root + '/v1.0'}/${route}`,
      params
    );
  };
}

export const axiosNodeServices = (root) => {
  return (method, api, data) => {
    const params = method === 'get' ? { params: data } : data;
    return axios[method](root + '/' + api, params);
  };
};

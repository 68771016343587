import { Link } from 'react-router-dom';
import { useState } from 'react';
import Modal from '@/components/Modal/Modal';
import CallusLayer from '@/components/CallusLayer/CallusLayer';
import './index.scss';

export default function FooterPage() {
  const [isOpen, setIsOpen] = useState(false);
  const LINK_LIST = [
    {
      type: '产品服务',
      list: [
        { name: '现代纸书服务', url: '/pdtserve#0', anchor: true },
        { name: '书小二', url: '/pdtserve#1', anchor: true },
        { name: '出版金融', url: '/pdtserve#2', anchor: true },
        { name: '编辑服务', url: '/pdtserve#3', anchor: true },
        { name: '元宇宙出版', url: '/pdtserve#4', anchor: true }
        // { name: '睿思数据', url: 'pdtserve#5', anchor: true },
        // { name: '小睿伴学', url: '/pdtserve#6', anchor: true }
      ]
    },
    {
      type: '案例平台',
      list: [
        { name: '合作案例', url: '/case#0', anchor: true },
        { name: '行业认可', url: '/case#1', anchor: true }
        // { name: '优秀案例', url: '/case' },
      ]
    },
    {
      type: '新闻资讯',
      list: [
        // { name: '公司新闻', url: '/news#category0' },
        { name: '集团动态', url: '/news#0', anchor: true },
        { name: '编辑创新培训 ', url: '/news#1', anchor: true },
        { name: '媒体报道', url: '/news#2', anchor: true }
        // { name: '基金公告', url: '/news#3', anchor: true }
      ]
    },
    {
      type: '联系我们',
      list: [
        // { name: '联系我们', url: '/aboutus' },
        { name: '市场合作', url: '', contact: true },
        { name: '加入我们', url: 'https://app.mokahr.com/social-recruitment/dcrays/39604', window: true }
        // { name: '投诉举报', url: '/aboutus#development' },
      ]
    },
    {
      type: '友情链接',
      list: [
        // { name: '国家新闻总署', url: 'nppa.gov.cn', window: true },
        { name: '国家新闻出版署', url: 'https://www.nppa.gov.cn/', window: true },
        { name: '重点实验室', url: 'http://www.pilwh.org.cn/', window: true },
        { name: '元宇宙书店', url: 'https://www.web3book.com.cn/', window: true },
        { name: '编辑创新大赛', url: 'https://www.necis.me/', window: true },
        { name: '武汉知识产权交易所', url: 'http://www.whipre.com/', window: true },
        { name: '北方知识产权交易中心', url: 'https://nncec.com/index.htm', window: true }
      ]
    }
  ];

  const ToTop = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  function getUlLiITtem(item, index) {
    if(!item.window && !item.contact && !item.anchor) {
      return <Link
        key={index}
        to={item.url}
        className="link-item"
        onClick={() => {
          item.fn && item.fn();
        }}
      >{item.name}</Link>;
    } else if(item.anchor) {
      return <span
        className="link-item"
        key={index}
        onClick={() => {
          window.location.href = item.url;
          ToTop();
        }}
      >{item.name}</span>;
    } else if(item.contact) {
      return <span
        className="link-item"
        key={index}
        onClick={() => {
          setIsOpen(true);
        }}
      >{item.name}</span>;
    } else {
      return <a
        className="link-item"
        target="_black"
        key={index}
        href={item.url}
      >{item.name}</a>;
    }
  }

  return <div className="FooterPage-ajfbajs2313">
    <div className="FooterPage-home-footer">
      <div className="info">
        <ul>
          {
            LINK_LIST.map((item, index) => (
              <li key={index} className={item.long ? 'long-li' : ''}>
                <p>{item.type}</p>
                {
                  item.list.map((item2, index2) => {
                    return getUlLiITtem(item2, index2);
                  })
                }
              </li>
            ))
          }
          <li className="long-li" key="long-li">
            <p>关注我们</p>
            <div className="account fr">
              <img src="https://oss.5rs.me/oss/uploadfe/png/35df78f7c3a1dc8e49a3cd99c5c0bf02.png" />
            </div>
            <p className="official">官方公众号</p>
          </li>
        </ul>
        <div className="line" />
        <div className="br" />
        <div className="company-info">
          {/* <div className="logo fr">
            <img src="https://oss.5rs.me/oss/uploadfe/png/624476c3ad3d9782fb97904d1c61f4b7.png" />
          </div> */}
          <div className="contact">
            {CONTACT_LIST.map(item => <div className={`item`} key={item.company}>
              <div className="icon fr">
                <img src="https://oss.5rs.me/oss/uploadfe/png/c09fed1f3989c1e63244972eaab2cd43.png" />
              </div>
              <div className="content">
                <div className="address">{item.company}地址：{item.address}</div>
              </div>
            </div>)}
          </div>
          {/* <div className="line" /> */}
          <div className="information">
            <h3>联系方式</h3>
            <p>电话：027-87381265&nbsp;&nbsp;&nbsp;021-53080288</p>
            <p>邮箱：dcg@dcrays.cn</p>
          </div>
        </div>
      </div>
    </div>
    {
      isOpen &&
      <Modal
        onModalClick={() => {
          setIsOpen(false);
        }}
        className={'modal'}
      >
        <CallusLayer
          onModalClick={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    }
  </div>;
}

const CONTACT_LIST = [
  {
    company: '武汉',
    address: '武汉市洪山区关山大道泛悦城 T2写字楼27层       '
  },

  {
    company: '上海',
    address: '上海市虹口区杨树浦路168号5层'
  },
  {
    company: '北京',
    address: '北京市朝阳区西大望路三号院蓝堡国际中心2座9层        '
  },
  {
    company: '香港',
    address: '香港中西区中环中心51层5103室       '
  }
];
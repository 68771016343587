
const hostIP = isDevelopment ? 'localhost' : window.location.hostname; //STEP2

const publicDomain = isDevelopment ? 'raysgo.com' : hostIP.substring(hostIP.indexOf('.') + 1);

const domainArr = ['raysgo.com', '5rs.me'];

const envArr = ['TEST', 'PRO'];
const envIndex = domainArr.findIndex(v => v === publicDomain); // 0测试环境，1生产环境;

// 系统角色
const systemCode = 'bookFamily';

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  systemCode,
  industryCaseId: systemId,
  systemCodeText: '编辑学堂管理平台',
  COOKIE: `${systemCode}_token`,
  env: envArr[envIndex],
  PRODUCTION_COOKIE: `${systemCode}_production_id`,
  iconfontURL: '//at.alicdn.com/t/font_1542801_kdyfn2gn11i.js'     // 字体图标库链接
};

export default config;

if(typeof window === 'undefined') {
  global.window = {
    location: {}
  };
}

export const isDevelopment = process.env.NODE_ENV !== 'production';

const HOST_NAME = window.location.hostname || '';
const DOMAIN = HOST_NAME.slice(HOST_NAME.indexOf('.') + 1);
const PROTOCOL = window.location.protocol;

export const BASE_API_ROOT = window.location.origin + (isDevelopment ? '/api/' : '/');
export const COOKIE = 'userInfo';
export const cookiePath = { path: '/', maxAge: 60 * 60 * 24 * 7 };
export const protocol = PROTOCOL;
export const domain = DOMAIN;

import Utils from '@/utils/utils';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.scss';

export default function NavMenu(props) {
  const { isBlueMenu = false, notMove, notHome, pageTabActive, onChangePageTab, isSmallScreen } = props;
  const [open, setOpen] = useState(false);
  const DROPDOWN_LIST = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="https://app.mokahr.com/social-recruitment/dcrays/39604" target="_black">
          <span className={!isBlueMenu ? 'other' : ''}>人才招聘</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/1e2889b2c9c23cd14be12b40dad79f68.png' : 'https://oss.5rs.me/oss/uploadfe/png/f194a3c83eddffb4dced51ade9ea35de.png'} />
          </div>
        </a>
      )
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" href="/csr" target="_black">
          <span className={!isBlueMenu ? 'other' : ''}>社会责任</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/48a1edb02f084cdbce233cb8ebfb6572.png' : 'https://oss.5rs.me/oss/uploadfe/png/40a76928fa59f5ddd11bb08b022d96ca.png'} />
          </div>
        </a>
      )
    },
    {
      key: '3',
      label: (
        <Link rel="noopener noreferrer" to="/aboutus">
          <span className={!isBlueMenu ? 'other' : ''}>关于我们</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/33eea56d6757e2a9a859da3e56f61f20.png' : 'https://oss.5rs.me/oss/uploadfe/png/cc50bef48220b4803de583fe5d0e4764.png'} />
          </div>
        </Link>
      )
    },
    {
      key: '4',
      label: (
        <Link rel="noopener noreferrer" to="/news">
          <span className={!isBlueMenu ? 'other' : ''}>新闻资讯</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/401c2b620adb998071972168b35ee401.png' : 'https://oss.5rs.me/oss/uploadfe/png/51701ddad000ce841d0325551ff802f7.png'} />
          </div>
        </Link>
      )
    },
    {
      key: '5',
      label: (
        <Link rel="noopener noreferrer" to="/case">
          <span className={!isBlueMenu ? 'other' : ''}>案例平台</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/a725510da2c3f6c473ec531d67625e04.png' : 'https://oss.5rs.me/oss/uploadfe/png/37bb9c6db13c5a517ea5ff012475949c.png'} />
          </div>
        </Link>
      )
    },
    {
      key: '6',
      label: (
        <Link rel="noopener noreferrer" to="/pdtserve">
          <span className={!isBlueMenu ? 'other' : ''}>产品服务</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/2a8c895331148188869965bdbc0c4ed7.png' : 'https://oss.5rs.me/oss/uploadfe/png/36dc5ca45122ab635ecfae3299388aa8.png'} />
          </div>
        </Link>
      )
    },
    {
      key: '7',
      label: (
        <Link rel="noopener noreferrer" to="/">
          <span className={!isBlueMenu ? 'other' : ''}>首&nbsp;&nbsp;页</span>
          <div className="icon">
            <img src={!isBlueMenu ? 'https://oss.5rs.me/oss/uploadfe/png/6d6050f2bdc661b9976f4b967ccfacc4.png' : 'https://oss.5rs.me/oss/uploadfe/png/a6921ead9dbc7860660c451ee4bf4ff9.png'} />
          </div>
        </Link>
      )
    }
  ];
  const ToTop = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return <>
    { (!Utils.getScreenHeight() || (Utils.getScreenHeight() && Utils.getScreenHeight() > 500)) &&
      (<div
        className={`navmenu ${isSmallScreen ? 'navmenu-m' : ''} ${notMove && 'move'} ${pageTabActive === 7 ? 'footer-nav' : ''}`}
        onMouseEnter={(e) => {
          if(isSmallScreen) return;
          setOpen(true); e.stopPropagation();
        }}
        onMouseLeave={() => {
          if(isSmallScreen) return;
          setOpen(false);
        }}
      >
        {isSmallScreen && <button className="navmenu-menu-btn" onClick={() => setOpen(!open) } />}
        <div
          className={`dropdown ${open ? `active ${isBlueMenu ? 'dropdown-deep' : 'dropdown-shallow'}` : `${isBlueMenu ? 'dropdown-deep h0' : 'dropdown-shallow h0'}`}`}
          onClick={() => {
            if(open) {
              ToTop();
            }
          }}
        >
          {DROPDOWN_LIST.reverse().map(item => item.label)}
        </div>
        <div
          className={`animation ${isBlueMenu ? `${open ? 'white-box' : 'blue-box'}` : `${open ? 'blue-box' : 'white-box'}`}`}
        >
          <div className={`cloumn1 cloumn ${open ? 'one-to-two' : ''} ${isBlueMenu ? 'blue-item' : `${open ? 'blue-item' : 'white-tem'}`}`} />
          <div className={`cloumn2 cloumn ${open ? 'two-rotate' : ''} ${isBlueMenu ? 'blue-item' : `${open ? 'blue-item' : 'white-tem'}`}`} />
          <div className={`cloumn3 cloumn ${open ? 'three-to-two' : ''} ${isBlueMenu ? 'blue-item' : `${open ? 'blue-item' : 'white-tem'}`}`} />
        </div>
      </div>)
    }
    <div className={`to-top ${isSmallScreen ? 'to-top-m' : ''} ${notMove && 'top-move'}`}>
      <img
        src={isBlueMenu || pageTabActive === 5 ? 'https://oss.5rs.me/oss/uploadfe/png/73fa7dfc17438d14d1e9b874dcfd4796.png' : 'https://oss.5rs.me/oss/uploadfe/png/b90a312af9e094adc150ebad58cf515e.png'}
        onClick={() => {
          if(isSmallScreen || notHome) {
            ToTop();
          } else {
            onChangePageTab(0);
          }
        }}
      />
    </div>
  </>;
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@/components/Modal/Modal';
import CallusLayer from '@/components/CallusLayer/CallusLayer';
import './Footer.scss';

export default function Footer(props) {
  const { enterAnimation, pageTabActive, notHome, isIndex, isSmallScreen } = props;
  const [isOpen, setIsOpen] = useState(false);
  const LINK_LIST = [
    {
      type: '产品服务',
      list: [
        { name: '现代纸书服务', url: '/pdtserve#0', anchor: true },
        { name: '书小二', url: '/pdtserve#1', anchor: true },
        { name: '出版金融', url: '/pdtserve#2', anchor: true },
        { name: '编辑服务', url: '/pdtserve#3', anchor: true },
        { name: '元宇宙出版', url: '/pdtserve#4', anchor: true }
        // { name: '睿思数据', url: 'pdtserve#5', anchor: true },
        // { name: '小睿伴学', url: '/pdtserve#6', anchor: true }
      ]
    },
    {
      type: '案例平台',
      list: [
        { name: '合作案例', url: '/case#0', anchor: true },
        { name: '行业认可', url: '/case#1', anchor: true }
        // { name: '优秀案例', url: '/case' },
      ]
    },
    {
      type: '新闻资讯',
      list: [
        // { name: '公司新闻', url: '/news#category0' },
        { name: '集团动态', url: '/news#0', anchor: true },
        { name: '编辑创新培训 ', url: '/news#1', anchor: true },
        { name: '媒体报道', url: '/news#2', anchor: true }
        // { name: '基金公告', url: '/news#3', anchor: true }
      ]
    },
    {
      type: '联系我们',
      list: [
        // { name: '联系我们', url: '/aboutus' },
        { name: '市场合作', url: '', contact: true },
        { name: '人才招聘', url: 'https://app.mokahr.com/social-recruitment/dcrays/39604', window: true }
        // { name: '投诉举报', url: '/aboutus#development' },
      ]
    },
    {
      type: '友情链接',
      list: [
        // { name: '国家新闻总署', url: 'nppa.gov.cn', window: true },
        { name: '国家新闻出版署', url: 'https://www.nppa.gov.cn/', window: true },
        { name: '重点实验室', url: 'http://www.pilwh.org.cn/', window: true },
        { name: '元宇宙书店', url: 'https://www.web3book.com.cn/', window: true },
        { name: '编辑创新大赛', url: 'https://www.necis.me/', window: true },
        { name: '武汉知识产权交易所', url: 'http://www.whipre.com/', window: true },
        { name: '北方知识产权交易中心', url: 'https://nncec.com/index.htm', window: true }
      ]
    }
  ];

  const ToTop = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  const getIcp = () => {
    let origin = window.location.origin;
    let ipcItem = RECORD_CODE.find(v => {
      return origin.includes(v.code);
    }) || {};
    return ipcItem.text || '鄂ICP备15003054号-19';
  };

  function getUlLiITtem(item, index) {
    if(!item.window && !item.contact && !item.anchor) {
      return <Link
        key={index}
        to={item.url}
        onClick={() => {
          item.fn && item.fn();
        }}
      >{item.name}</Link>;
    } else if(item.anchor) {
      return <span
        key={index}
        onClick={() => {
          window.location.href = item.url;
          ToTop();
        }}
      >{item.name}</span>;
    } else if(item.contact) {
      return <span
        key={index}
        onClick={() => {
          setIsOpen(true);
        }}
      >{item.name}</span>;
    } else {
      return <a
        target="_black"
        key={index}
        href={item.url}
      >{item.name}</a>;
    }
  }

  return <div className={`home-footer ${isSmallScreen && 'home-footer-m'} ${enterAnimation && (pageTabActive === 7 || isSmallScreen) && 'enterAnimation'} ${notHome && 'notHome'}`}>
    <div className="home-footer-container">
      <div className="info">
        <ul>
          {
            LINK_LIST.map((item, index) => (
              <li key={index} className={item.long ? 'long-li' : ''}>
                <p>{item.type}</p>
                {
                  item.list.map((item2, index2) => {
                    return getUlLiITtem(item2, index2);
                  })
                }
              </li>
            ))
          }
          <li className="long-li" key="long-li">
            <p>关注我们</p>
            <div className="account fr">
              <img src="https://oss.5rs.me/oss/uploadfe/png/35df78f7c3a1dc8e49a3cd99c5c0bf02.png" />
            </div>
            <p className="official">官方公众号</p>
          </li>
        </ul>
        <div className="br" />
        <div className="company-info">
          <div className="logo fr">
            <img src="https://oss.5rs.me/oss/uploadfe/png/624476c3ad3d9782fb97904d1c61f4b7.png" />
          </div>
          <div className="contact">
            {CONTACT_LIST.map(item => <div className={`item ${isIndex && 'index-item'}`} key={item.company}>
              <div className="icon fr">
                <img src="https://oss.5rs.me/oss/uploadfe/png/c09fed1f3989c1e63244972eaab2cd43.png" />
              </div>
              <div className="content">
                <div className="address">{item.company}地址：{item.address}</div>
              </div>
            </div>)}
            {isIndex && <a target="_black" href="https://beian.miit.gov.cn">{getIcp()}</a>}
          </div>
          <div className="line" />
          <div className="information">
            <h3>联系方式</h3>
            <p>电话：027-87381265&nbsp;&nbsp;&nbsp;021-53080288</p>
            <p>邮箱：dcg@dcrays.cn</p>
          </div>
        </div>
      </div>
    </div>
    {
      isOpen &&
      <Modal
        onModalClick={() => {
          setIsOpen(false);
        }}
        className={'modal'}
      >
        <CallusLayer
          onModalClick={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    }
  </div>;

}
const CONTACT_LIST = [
  {
    company: '武汉',
    address: '武汉市洪山区关山大道泛悦城 T2写字楼27层       '
  },

  {
    company: '上海',
    address: '上海市虹口区杨树浦路168号5层'
  },
  {
    company: '北京',
    address: '北京市朝阳区西大望路三号院蓝堡国际中心2座9层        '
  },
  {
    company: '香港',
    address: '香港中西区中环中心51层5103室       '
  }
];

const RECORD_CODE = [
  {
    code: 'pilwh.org.cn',
    text: '鄂ICP备15003054号-18'
  },
  {
    code: 'k12zwh.com',
    text: '鄂ICP备15003054号-17'
  },
  {
    code: 'vheals.com',
    text: '鄂ICP备15003054号-16'
  },
  {
    code: 'vsays.cn',
    text: '鄂ICP备15003054号-16'
  },
  {
    code: 'vpays.cn',
    text: '鄂ICP备15003054号-16'
  },
  {
    code: 'rayskjjb.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysthink.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayschuban.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raystime.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayskjwx.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysxnjtdx.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayswx.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayscjwy.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayszy.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysclab.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayswechat.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysgo.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayswy.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysmen.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysnjdx.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayswdcb.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysyun.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayswang.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysbus.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayssea.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysren.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayslib.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysweixin.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayscjcb.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayssky.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysabc.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayshbjy.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayscjsn.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'rayshqrw.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'raysblue.com',
    text: '鄂ICP备15003054号-14'
  },
  {
    code: 'codebook.net.cn',
    text: '鄂ICP备15003054号-13'
  },
  {
    code: 'codebook.com.cn',
    text: '鄂ICP备15003054号-13'
  },
  {
    code: 'kk-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'answer-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'listen-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'bm-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'bdc-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'tk-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'vote-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'article-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'qa-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'book-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'pdf-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'video-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'audio-rays.com',
    text: '鄂ICP备15003054号-10'
  },
  {
    code: 'wechuban.com',
    text: '鄂ICP备15003054号-12'
  },
  {
    code: 'vbianji.com',
    text: '鄂ICP备15003054号-12'
  },
  {
    code: 'wetushu.com',
    text: '鄂ICP备15003054号-12'
  },
  {
    code: 'hoguu.com',
    text: '鄂ICP备15003054号-12'
  },
  {
    code: 'necis.me',
    text: '鄂ICP备15003054号-9'
  },
  {
    code: 'dev-dcrays.cn',
    text: '鄂ICP备15003054号-12'
  },
  {
    code: 'edu2-dcrays.cn',
    text: '鄂ICP备15003054号-12'
  },
  {
    code: 'edu1-dcrays.cn',
    text: '鄂ICP备15003054号-8'
  },
  {
    code: 'chubanyun.net',
    text: '鄂ICP备15003054号-7'
  },
  {
    code: 'chubanyun.me',
    text: '鄂ICP备15003054号-5'
  },
  {
    code: '5rs.me',
    text: '鄂ICP备15003054号-4'
  },
  {
    code: 'techdc.cn',
    text: '鄂ICP备15003054号-16'
  },
  {
    code: 'dcrays.cn',
    text: '鄂ICP备15003054号-19'
  },
  {
    code: 'dcrays.com',
    text: '鄂ICP备15003054号-3'
  },
  {
    code: 'duoyue.me',
    text: '鄂ICP备15003054号-11'
  }
];
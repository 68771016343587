import gsap from 'gsap';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

export default function NewHeader() {

  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  // const [safeLoginLayer, setSafeLoginLayer] = useState(false);

  const clickMenu = () => {
    if(showMenu) {
      gsap.to('.NewHeader-shdhfalk1123 .NewHeader-menu', {
        yPercent: -100,
        y: 0,
        duration: 0.4,
        transformOrigin: 'top center'
      });
    } else {
      gsap.to('.NewHeader-shdhfalk1123 .NewHeader-menu', {
        yPercent: 0,
        y: 0,
        duration: 0.4,
        transformOrigin: 'top center'
      });
    }
    setShowMenu(!showMenu);
  };

  const navigateTo = (url, showWindow) => {
    clickMenu();
    if(showWindow) {
      window.open(url);
    } else {
      history.push(url);
    }
  };

  return <div className="NewHeader-shdhfalk1123">
    { showMenu && <div className="mask" onClick={clickMenu} onTouchMove={clickMenu} /> }
    <div className="NewHeader-header">
      <img src="https://oss.5rs.me/oss/uploadfe/png/48586f95084a5392fe7c40f75ea6b335.png" />
      {/* <div>
        <img src="https://oss.5rs.me/oss/uploadfe/png/a125228bf79eaceb823a16dbf6a40b40.png" />
      </div> */}
      {
        !showMenu ? <img onClick={clickMenu} src="https://oss.5rs.me/oss/uploadfe/png/e2ecf865d084fc468f2d6f0717580468.png" /> :
        <img style={{ width: 13, height: 13 }} onClick={clickMenu} src="https://oss.5rs.me/oss/uploadfe/png/b462896798e54a2f757f7c4cbbed6c94.png" />
      }
    </div>
    <ul className="NewHeader-menu">
      {
        NAV_LIST.map((item, index) => (
          <li key={index} onClick={() => navigateTo(item.url ? item.url : '/', item.window)}>{item.content}</li>
        ))
      }
    </ul>
  </div>;
}

const NAV_LIST = [
  { 'content': '首页', url: '/' },
  { 'content': '产品服务', url: '/pdtserve' },
  { 'content': '案例平台', url: '/case' },
  { 'content': '新闻资讯', url: '/news' },
  { 'content': '关于我们', url: '/aboutus' },
  { 'content': '社会责任', url: '/csr' },
  { 'content': '人才招聘', url: 'https://app.mokahr.com/social-recruitment/dcrays/39604', window: true }
];
import React from 'react';
import classNames from 'classnames';

function Agreement(props) {

  const { onChange, className, hasPersonal } = props;

  return (
    <span className={classNames('dis-flex mt20', className)}>
      <input
        type="checkbox"
        name="isAgreed"
        itemClass={classNames(className)}
        onChange={onChange}
      />
      <span className="ml10">
        <span>我已阅读并同意</span>
        {
          hasPersonal &&
          <>
            <a className="text-primary" target="_blank" href={AGREEMENT_TYPE.PERSONAL.linkUrl}>《个人信息授权声明》</a>
            <span>和</span>
          </>
        }
        <a className="text-primary" target="_blank" href={AGREEMENT_TYPE.PRIVARY.linkUrl}>《隐私政策》</a>
      </span>
    </span>
  );
}

Agreement.defaultProps = {
  className: '',
  hasPersonal: true,
  onChange: () => {}
};

export default Agreement;

const AGREEMENT_TYPE = {
  PERSONAL: {
    type: 'PERSONAL',
    linkUrl: 'https://oss.5rs.me/oss/uploadfe/html/rays_decleare.html'
  },
  PRIVARY: {
    type: 'PRIVARY',
    linkUrl: 'https://oss.5rs.me/oss/uploadfe/html/rays_yinsi.html'
  }
};

import axiosServices from './request';

const ReaderCenter = axiosServices('readercenter');

const ResourceCenter = axiosServices('resourcecenter');

const UserCenter = axiosServices('usercenter');

const commoncenter = axiosServices('commoncenter');

const message = axiosServices('message');

const exapi = axiosServices('exapi');

const scenarizedapp = axiosServices('scenarizedapp');

export default {
  getUserInfoByOpenId: (data) => {
    return ReaderCenter('get', 'wechatUser/getUserInfoByOpenId', data);
  },
  editUserInfo: (data) => {
    return ReaderCenter('post', 'wechatUser/saveWechatUserExtendInfo', data);
  },
  getImages: (data) => {
    return ResourceCenter('get', 'image/wallpaper', data);
  },
  getPartnerList: (data) => {
    return commoncenter('get', 'partner/getPartnerListOutPage', data);
  },
  getBlockPageList: (data) => {
    return commoncenter('get', 'marketUpdate/getList', data);
  },
  sendEmail: (data) => {
    return message('post', 'email/sendLeaveMassageEmail', data);
  },
  sendMessage: (data) => {
    return message('post', 'shortMessage/sendJoinMessage', data);
  },
  getUserHeadPic: (data) => {
    return exapi('get', 'tv/getRecentData', data);
  },
  addTourist: (data) => {
    return UserCenter('post', 'common/addTourist', data);
  },
  getArticelInfo: (data) => {
    return commoncenter('get', 'marketUpdate/getById', data);
  },
  /** 查询图书案例分类 */
  getBookScanTypeList: data => commoncenter('get', 'bookQrcodeScanType/getList', data),
  /** 查询图书案例分页 */
  getBookScanPage: data => commoncenter('get', 'bookQrcodeScanExperience/getPage', data),
  /** 查询官网应用案例分页  */
  getAppScanPage: data => commoncenter('get', 'appQrcodeScanExperience/getPage', data),
  /** 查询官网商城案例分页  */
  getStoreScanPage: data => commoncenter('get', 'shopmallQrcodeScanExperience/getPage', data),

  /** 联系我们-发送邮件  */
  sendNodeEmails: data => message('post', 'emailV2/sendEmail', data),

  // 获取新闻咨询-行业简报
  getIndustryBulletin: data => scenarizedapp('get', 'officialwebsite/getIndustryBulletin', data)
};
